import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Card } from "../minor-components/Card";
import { baseURL } from "../../constants/baseURL";
import { SlickSliders } from "../minor-components/SlickSlider";
import { Footer } from "./Footer";
import Noty from "noty";
import { axiosInstance } from "../../constants/axiosInstance";
import { useAuth } from "../../authProvider";

export const AllProducts = () => {
  let navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchProducts();
      navigate("/all-products");
    } else {
      navigate("/login");
    }
  }, []);

  const [products, setProducts] = useState([]);
  const { setAllProducts, filteredProducts, setFilteredProducts } = useAuth();

  useEffect(() => {
    if (location.state?.filteredProducts) {
      setFilteredProducts(location.state?.filteredProducts);
    }
  }, [location.state]);
  const fetchProducts = async () => {
    const data = await fetch(`${baseURL}/api/v1/product/getproducts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const jsonData = await data.json();

    setProducts(jsonData.data);
    setAllProducts(jsonData.data);
  };

  const [credentials, setcredentials] = useState({
    userName: "",
    chatId: "",
    formattedAddress: "",
    referralCode: "",
    role: "",
  });

  const fetchUser = async () => {
    await axiosInstance
      .get("/api/v1/user/getsingleuser", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setcredentials({
          userName: response.data.data.userName,
          chatId: response.data.data.chatId,
          formattedAddress: response.data.data.formattedAddress,
          referralCode: response.data.data.referralCode,
          role: response.data.data.role,
        });
      })
      .catch((err) => {
        new Noty({
          type: "error",
          timeout: 2000,
          text: "Something Went Wrong",
        }).show();
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchUser();
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <div className="w-[90vw] mx-auto my-40">
        <h1 className="text-primaryText font-bold text-2xl mb-6">
          Best Products
        </h1>
        <div className="grid place-items-center grid-cols-2 sm:place-items-start sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
          {filteredProducts.length > 0
            ? filteredProducts.map((item, i) => (
                <div className="w-fit" key={i}>
                  <Card
                    name={item.name}
                    productPhoto={item.productPhoto}
                    id={item._id}
                    title={item.name}
                    description={item.description}
                    price={item.price}
                    brand={item.brand}
                    category={item.category}
                    type={item.type}
                    shopId={item.shopid}
                    key={item._id}
                    delivery={item.delivery}
                    role={credentials.role !== "" ? credentials.role : ""}
                    rolePrices={item.rolePrices}
                    variations={item.variations}
                    units={item.units}
                  />{" "}
                </div>
              ))
            : products.map((item, i) => (
                <div className="w-fit" key={i}>
                  <Card
                    name={item.name}
                    productPhoto={item.productPhoto}
                    id={item._id}
                    title={item.name}
                    description={item.description}
                    price={item.price}
                    brand={item.brand}
                    category={item.category}
                    type={item.type}
                    shopId={item.shopid}
                    key={item._id}
                    delivery={item.delivery}
                    role={credentials.role !== "" ? credentials.role : ""}
                    rolePrices={item.rolePrices}
                    variations={item.variations}
                    units={item.units}
                  />{" "}
                </div>
              ))}
        </div>

        <div className="bg-white">
          <div>
            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative z-10 flex items-baseline justify-between pt-24 pb-6 "></div>
              <section
                aria-labelledby="products-heading"
                className="pt-6 pb-24"
              >
                <h2 id="products-heading" className="sr-only">
                  Products
                </h2>
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10 ">
                  {/* Filters */}
                  <form className=" lg:block">
                    <h1 className="text-xl font-bold tracking-tight text-gray-900">
                      All Stores
                    </h1>
                    <div className=" py-6 grid grid-cols-1 ">
                      <div>
                        <h1 className="text-lg font-medium tracking-tight text-gray-500">
                          Sort
                        </h1>
                        <div className="space-y-4 pt-3">
                          <div className="flex items-center">
                            <input
                              id="filter-color-0"
                              name="color[]"
                              defaultValue="white"
                              type="checkbox"
                              className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="filter-color-0"
                              className="ml-3 text-sm text-gray-600"
                            >
                              {" "}
                              Pick for you (Default){" "}
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-color-1"
                              name="color[]"
                              defaultValue="beige"
                              type="checkbox"
                              className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="filter-color-1"
                              className="ml-3 text-sm text-gray-600"
                            >
                              {" "}
                              Most popular{" "}
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-color-3"
                              name="color[]"
                              defaultValue="brown"
                              type="checkbox"
                              className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="filter-color-3"
                              className="ml-3 text-sm text-gray-600"
                            >
                              {" "}
                              Rating{" "}
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-color-4"
                              name="color[]"
                              defaultValue="green"
                              type="checkbox"
                              className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="filter-color-4"
                              className="ml-3 text-sm text-gray-600"
                            >
                              {" "}
                              Delivery Time{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                      <h1 className="text-lg font-medium  text-gray-500 mt-5 border-0">
                        Max Delivery Fee
                      </h1>
                      <div className="grid grid-cols-4 ">
                        <div className="rounded-full h-10 w-10 active flex  m-2 justify-center items-center">
                          <button className="rounded-full  flex border-2 py-2 px-3 justify-center items-center">
                            €12
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  {/* Product grid */}
                  <div className="lg:col-span-3 ">
                    <div className="flex justify-between ">
                      <h1 className="text-xl font-bold tracking-tight text-gray-900">
                        30% off To Prevent Those Sunday Scaries
                      </h1>
                      <Link to="#">See all {products.length} </Link>
                    </div>
                    <div className="pt-9">
                      <SlickSliders className="flex">
                        {products.map((item, i) => (
                          <div className="px-4" key={i}>
                            <Card
                              name={item.name}
                              productPhoto={item.productPhoto}
                              id={item._id}
                              title={item.name}
                              description={item.description}
                              price={item.price}
                              brand={item.brand}
                              category={item.category}
                              type={item.type}
                              shopId={item.shopid}
                              key={item._id}
                              delivery={item.delivery}
                              role={
                                credentials.role !== "" ? credentials.role : ""
                              }
                              rolePrices={item.rolePrices}
                              variations={item.variations}
                            />{" "}
                          </div>
                        ))}
                      </SlickSliders>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};
