/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosInstance } from "../../constants/axiosInstance";
import { useAlert } from "react-alert";

export const CheckoutProducts = ({ fetchCart, ...product }) => {
  const [status, setStatus] = useState(false);
  const [quantityValue, setQuantityValue] = useState(0);

  let num = 0;
  const alert = useAlert();
  // let total = 0;

  const handleCart = async (e, action) => {
    if (action === true) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  };

  const handleCartProducts = async (e, action, variationName) => {
    let quantities = document.querySelectorAll(
      `[data-value="${e.target.dataset.keyvalue}"]`
    );
    quantities.forEach((quantity, index) => {
      if (action === "plus") {
        num = parseInt(quantity.value);
        num += 1;
        quantity.value = num;

        setQuantityValue((prev) => prev + 1);
        let obj = {
          id: product.cartProductId,
          quantity: 1,
          price: product.cartProductPrice,
          variationName: variationName ? [variationName.toLowerCase()] : [],
        };
        if (quantity.value <= product.cartProductUnits) {
          product.getItem(obj, "p");
        }
      } else if (action === "minus") {
        num = parseInt(quantity.value);
        num = num - 1;
        quantity.value = num;
        let obj = {
          id: product.cartProductId,
          quantity: num,
          price: product.cartProductPrice,
          variationName: variationName ? [variationName.toLowerCase()] : [],
        };
        product.getItem(obj, "m");
      }
    });
  };

  const handleDeleteProductFromCart = async (productId) => {
    try {
      const response = await axiosInstance.post(
        "/api/v1/order/deleteCartProductById",
        {
          productId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      alert.show(response.data.message);
      localStorage.removeItem("cartItems");
      fetchCart();

      window.location.reload();
    } catch (error) {
      alert.show(error.message);
    }
  };

  return (
    <>
      {product.cartProductQuantity !== 0 ? (
        <>
          <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6 ">
            <div className="flex flex-col rounded-lg bg-white sm:flex-row relative">
              {typeof product.cartProductImage === "string" &&
                !product.cartProductImage.endsWith(".mp4") && (
                  <img
                    className="mx-2 mt-2 h-24 w-28 rounded-md border object-cover object-center"
                    src={product.cartProductImage}
                    alt="prod"
                  />
                )}
              {typeof product.cartProductImage !== "string" &&
                !product.cartProductImage[0].endsWith(".mp4") && (
                  <img
                    className="mx-2 mt-2  h-24 w-28 rounded-md border object-cover object-center"
                    src={product.cartProductImage[0]}
                    alt="prod"
                  />
                )}

              {typeof product.cartProductImage !== "string" &&
                product.cartProductImage[0].endsWith(".mp4") && (
                  <video
                    className="mx-2 mt-2  h-24 w-28 rounded-md border object-cover object-center"
                    controls={false}
                    autoPlay
                    loop
                  >
                    <source
                      src={product.cartProductImage[0]}
                      type="video/mp4"
                    ></source>
                  </video>
                )}

              {product.cartProductQuantity !== 0 && (
                <>
                  {product.productVariations.length <= 0 ? (
                    <div className="flex w-full flex-col px-4 py-4">
                      <span className="font-semibold">
                        {/* Units: {product.cartProductUnits} */}
                      </span>
                      <span className="float-right text-gray-400">
                        €{product.cartProductPrice} x{" "}
                        {product.cartProductQuantity}
                      </span>
                      <p className="text-lg font-bold">
                        €
                        {product.cartProductPrice * product.cartProductQuantity}
                      </p>
                    </div>
                  ) : (
                    <div className="flex w-full flex-col px-4 py-4">
                      <span className="font-semibold">
                        Total: €
                        {product.cartProductPrice * product.cartProductQuantity}
                      </span>
                      {product.productVariations.map((variation, i) => {
                        return (
                          <span
                            className="flex justify-between gap-1 capitalize w-full lg:gap-2"
                            key={i}
                          >
                            <span className="text-base font-medium flex flex-col">
                              {variation.name}
                              <span className="text-sm text-gray-400 font-normal">
                                {variation.price}x{variation.quantity}
                              </span>
                            </span>

                            <span className="flex flex-row h-6 w-full max-w-[150px] border border-[#FFFFFF]-600 rounded-lg relative opacity-[0.67] bg-[#000000] hover:text-[#000000]-700 hover:bg-[#000000]-400 bg-transparent mt-1">
                              <button
                                onClick={
                                  variation.quantity !== 0
                                    ? (e) =>
                                        handleCartProducts(
                                          e,
                                          "minus",
                                          variation.name
                                        )
                                    : (e) => handleCart
                                }
                                data-keyvalue={variation.quantity}
                                className={`flex items-center w-full justify-center border-[#FFFFFF]-600 opacity-[0.67] bg-[#000000]  text-white rounded-l-[20%]  h-full   ${
                                  product.cartProductQuantity !== 0
                                    ? "cursor-pointer"
                                    : "cursor-not-allowed "
                                } outline-none m-auto text-lg font-thin`}
                              >
                                -
                              </button>
                              <input
                                type="text"
                                data-value={variation.quantity}
                                id="quantity"
                                readOnly
                                className="quantity focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-gray-600 focus:text-gray-600  md:text-basecursor-default flex items-center text-gray-700 outline-none"
                                name="custom-input-number"
                                value={variation.quantity}
                              />
                              <button
                                onClick={(e) =>
                                  handleCartProducts(e, "plus", variation.name)
                                }
                                data-keyvalue={variation.quantity}
                                className=" flex items-center w-full border-[#FFFFFF]-600  opacity-[0.67] rounded-r-[20%] justify-center bg-[#000000] text-white text-lg hover:text-[#000000]-700 hover:bg-[#000000]-400 h-full  cursor-pointer m-auto  font-thin disabled:opacity-30 disabled:bg-[rgba(0,0,0,0.3)]"
                                disabled={
                                  quantityValue >= product.cartProductUnits
                                }
                              >
                                +
                              </button>
                            </span>
                          </span>
                        );
                      })}
                    </div>
                  )}

                  <div className="flex w-full flex-col px-4 py-4 ">
                    <div
                      className={`custom-number-input w-28 absolute top-0 right-0 ${
                        product.productVariations.length <= 0 ? "m-[4%]" : "m-5"
                      }`}
                    >
                      {product.productVariations.length <= 0 && (
                        <div className="flex flex-row h-6 w-full border border-[#FFFFFF]-600 rounded-lg relative opacity-[0.67] bg-[#000000] hover:text-[#000000]-700 hover:bg-[#000000]-400 bg-transparent mt-1">
                          <button
                            onClick={
                              product.cartProductQuantity !== 0
                                ? (e) => handleCartProducts(e, "minus")
                                : (e) => handleCart
                            }
                            data-keyvalue={product.cartProductQuantity}
                            className={`flex items-center w-full justify-center border-[#FFFFFF]-600 opacity-[0.67] bg-[#000000]  text-white rounded-l-[20%]  h-full   ${
                              product.cartProductQuantity !== 0
                                ? "cursor-pointer"
                                : "cursor-not-allowed "
                            } outline-none m-auto text-lg font-thin`}
                          >
                            -
                          </button>
                          <input
                            type="text"
                            data-value={product.cartProductQuantity}
                            id="quantity"
                            readOnly
                            className="quantity focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-gray-600 focus:text-gray-600  md:text-basecursor-default flex items-center text-gray-700 outline-none"
                            name="custom-input-number"
                            value={product.cartProductQuantity}
                          />
                          <button
                            onClick={(e) => handleCartProducts(e, "plus")}
                            data-keyvalue={product.cartProductQuantity}
                            className=" flex items-center w-full border-[#FFFFFF]-600  opacity-[0.67] rounded-r-[20%] justify-center bg-[#000000] text-white text-lg hover:text-[#000000]-700 hover:bg-[#000000]-400 h-full  cursor-pointer m-auto  font-thin disabled:opacity-30 disabled:bg-[rgba(0,0,0,0.3)]"
                            disabled={quantityValue >= product.cartProductUnits}
                          >
                            +
                          </button>
                        </div>
                      )}

                      <div
                        className={`${
                          product.productVariations.length > 0
                            ? "text-right"
                            : "mt-5 text-right"
                        }`}
                      >
                        <button
                          className="text-[rgb(239,68,68)]"
                          onClick={() =>
                            handleDeleteProductFromCart(product.cartProductId)
                          }
                        >
                          <FontAwesomeIcon icon="fa-solid fa-trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="flex w-full flex-col px-2 m-0">
              <span className="font-semibold">
                {product.cartProductName} - {product.cartProductCategory}
              </span>
              {product.productVariations.length > 0 && (
                <span className="text-sm text-gray-400">
                  (
                  {product.productVariations.map((variation, i) => {
                    return (
                      <span
                        className="text-sm text-gray-400 capitalize"
                        key={i}
                      >
                        {variation.name}
                        {i === product.productVariations.length - 1 ? "" : ", "}
                      </span>
                    );
                  })}
                  )
                </span>
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
