import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavLogo from "../../assets/logo.png";
import { Loader } from "../minor-components/Loader";
import { useAlert } from "react-alert";
import { axiosInstance } from "../../constants/axiosInstance";
import io from "socket.io-client";

export const SignUp = () => {
  const [credentials, setcredentials] = useState({
    password: "",
    chatId: "",
    userName: "",
  });
  const [loading, setLoading] = useState(false);
  const [urlToken, setUrlToken] = useState("");
  const [referralcode, setReferralCode] = useState("");
  const navigate = useNavigate();
  const alert = useAlert();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const referralCode = queryParams.get("referral");

    setReferralCode(referralCode);

    setUrlToken(token);
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { password, chatId, userName } = credentials;
    if (!password || !chatId || !userName) {
      setLoading(false);
      alert.show("Please fill all fields.");
      return;
    }

    const payload = {
      ...credentials,
      urlToken,
      referrersCode: referralcode,
    };
    try {
      const response = await axiosInstance.post(
        "/api/v1/user/usersignup",
        payload
      );
      if (response.data.success) {
        setLoading(false);
        localStorage.setItem("token", response.data.data.token);
        alert.show(
          "Thanks for registration, you will get a confirmation of registration on your telegram account via our bot."
        );
        const socket = io(process.env.NODE_LOCAL_SERVER);
        socket.on("newUser", () => {
          localStorage.setItem("notification", 2);
        });
        navigateToLogin("login");
      }
    } catch (err) {
      setLoading(false);
      alert.show(
        err.response?.data?.message || "Error during signup. Please try again."
      );
    }
  };

  const navigateToLogin = (purpose) => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert.show("No authentication token found. Please log in.");
      return;
    }

    axiosInstance
      .post(
        "/api/v1/tokens/generate",
        { purpose },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        const newToken = response.data.token;
        navigate(`/${purpose}?token=${newToken}`);
      })
      .catch((error) => {
        console.error("Error generating token:", error);
        alert.show("Failed to generate link. Please try again.");
      });
  };

  const onChange = (e) => {
    setcredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <>
      {!loading ? (
        <div className="h-[calc(100vh_-_5rem)] flex  bg-emerald-50 flex-col items-center justify-center py-20  px-4 sm:px-6 lg:px-8">
          <div className="w-full lg:w-4/12  md:w-6/12 shadow-md rounded-md bg-white flex justify-center">
            <div className="h-auto p-8 w-full">
              <div className="w-full">
                <div className="w-full">
                  <img
                    className="mx-auto w-[85px]"
                    src={NavLogo}
                    alt="Workflow"
                  />
                  <h2 className="mt-2 text-center text-2xl font-normal text-gray-600">
                    Account Sign Up
                  </h2>
                </div>
                <form className="mt-8" onSubmit={handleSubmit}>
                  <div className="rounded-md shadow-sm">
                    <div>
                      <div className="mb-2 mt-2 ">
                        <label className="font-bold">Telegram Username</label>
                      </div>
                      <input
                        id="userName"
                        name="userName"
                        type="text"
                        autoComplete="userName"
                        required
                        className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-myBg-500 focus:border-myBg-500 focus:z-10 sm:text-sm"
                        placeholder="Username"
                        onChange={onChange}
                      />
                    </div>
                    <div className="mb-2 mt-2">
                      <label htmlFor="chatId" className="font-bold">
                        Telegram Chat Id
                      </label>
                      <div className="flex gap-2 items-center">
                        <input
                          id="chatId"
                          name="chatId"
                          type="text"
                          required
                          className="appearance-none rounded-md w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-myBg-500 focus:border-myBg-500"
                          placeholder="Chat Id"
                          onChange={onChange}
                        />
                        <a
                          href="https://t.me/BuildingBloquesBot"
                          target="_blank"
                          rel="noreferrer"
                          className="w-full px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-myBg hover:bg-myBg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-myBg-500 text-center"
                        >
                          Get Chat Id
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="mb-2 mt-2 ">
                        <label className="font-bold">Password</label>
                      </div>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-myBg-500 focus:border-myBg-500 focus:z-10 sm:text-sm"
                        placeholder="Password"
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="group relative w-full my-2 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-secondaryText bg-myBg hover:bg-myBg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-myBg-500"
                    >
                      Sign Up
                    </button>
                  </div>
                  <div className="flex w-full items-center justify-end">
                    <div className="text-sm">
                      Already have Credentials? Request for login link.
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
